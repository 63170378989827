import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getRegex } from './get-regex';

/** Usage:
 * <input type="text" [(ngModel)]="filter">
 * <div [innerHTML]="myAwesomeText  | highlight : filter"></div>
 * src https://gist.github.com/adamrecsko/0f28f474eca63e0279455476cc11eca7
 */

@Pipe({
    name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
    constructor(public sanitizer: DomSanitizer) {}

    transform(text: string, search: string): SafeHtml {
        if (search && text) {
            const regex = getRegex(search);

            const newText = text.replace(
                regex,
                (match) =>
                    `<span style="background-color: #1A85FF; border-radius: 0px; color: #fff">${match}</span>`,
            );

            return this.sanitizer.bypassSecurityTrustHtml(newText);
        } else {
            return text;
        }
    }
}
