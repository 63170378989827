export const getRegex = (search: string) => {
    let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    pattern = pattern
        .split(' ')
        .filter((t) => t)
        .join('|');
    pattern = pattern
        .replace(/[^а-яёa-z0-9|]/gi, '')
        .replace(/(\<(\/?[^>]+)>)/gi, '$&[^а-яёa-z0-9]*');
    const regex = new RegExp(pattern, 'gi');

    return regex;
};
